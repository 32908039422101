

<template>
  <div class="profile-container">
    <div class="profile-info">
      <img :src="userAvatar" class="profile-image"/>
      <div class="profile-text">
        <van-button type="primary" @click="goToLogin">登录</van-button>
        <van-button style="margin-left: 20px" type="" @click="goToRegister">注册</van-button>
      </div>
    </div>
    <div class="personal-greeting">
      <p>欢迎，今天是 {{ currentDate }}，{{ currentWeekday }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileInfo',
  data() {
    return {
      userAvatar: require('@/assets/登录.png'),
      currentDate: "",
      currentWeekday: ""
    };
  },
  mounted() {
    this.setCurrentDate();
  },
  methods: {
    setCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const daysOfWeek = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      const weekday = daysOfWeek[today.getDay()];

      this.currentDate = `${year}年${month}月${day}日`;
      this.currentWeekday = weekday;
    },
    // 添加路由跳转方法
    goToLogin() {
      this.$router.push('/login');
    },
    goToRegister() {
      this.$router.push('/register');
    }
  }
};
</script>


<style scoped>
.profile-container {
  width: 100%;
  background-color: #ffffff;
  padding: 0.6rem 0;
  margin-bottom: 0.6rem;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  border-radius: 15%;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.profile-text {
  flex-grow: 1;
}


.personal-greeting {
  text-align: center;
  font-size: 0.8rem;
  color: #666;
  word-wrap: break-word;
}


</style>


