export const personalPageCategories = [
    {id: 0, name: '浏览足迹', basePath: '/history-page', icon: require("@/assets/PersonalInfoPage/浏览足迹.svg")},
    {id: 1, name: '发布管理', basePath: '/rentals', icon: require("@/assets/PersonalInfoPage/发布管理.svg")},
    {id: 2, name: '钱包与贡献值', basePath: '/business-transfer', icon: require("@/assets/PersonalInfoPage/钱包.svg")},
    {id: 3, name: '我的地址', basePath: '/second-hand-items', icon: require("@/assets/PersonalInfoPage/我的地址.svg")},


    {id: 7, name: '问题反馈', basePath: '/feedback', icon: require("@/assets/PersonalInfoPage/问题反馈.svg")},
    {id: 8, name: '关于平台', basePath: '/parenting-circle', icon: require("@/assets/PersonalInfoPage/关于平台.svg")},
    {id: 9, name: '用户须知', basePath: '/UserServiceTerms', icon: require("@/assets/PersonalInfoPage/用户须知.svg")},

];


