<template>
  <!-- 个人信息 -->
  <ProfileInfo />

  <!-- 菜单项 -->
  <div class="menu-container">
    <div v-for="(item, index) in personalPageCategories.slice(0, 4)" :key="item.id" class="menu-scroll">
      <div class="menu-item" @click="navigateToPage(item.basePath)">
        <img :src="item.icon" class="menu-icon"/>
        <div class="menu-text">
          <p class="menu-title">{{ item.name }}</p>
        </div>
        <van-icon name="arrow" class="menu-arrow"/>
      </div>
      <div v-if="index !== personalPageCategories.length - 1" class="divider"></div>
    </div>
  </div>

  <div class="mini-menu-grid">
    <div v-for="(item, index) in personalPageCategories.slice(4)" :key="index" class="mini-menu-item"
         @click="navigateToPage(item.basePath)">
      <img :src="item.icon" class="mini-item-icon"/>
      <p class="mini-item-title">{{ item.name }}</p>
    </div>
  </div>
  <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa' }">
    重庆酷喵喵科技有限公司-专注重庆资讯平台
  </van-divider>
</template>

<script>
import { personalPageCategories } from "@/js/personalPageCategories";
import ProfileInfo from "@/components/home/item/ProfileInfo";

export default {
  components: {
    ProfileInfo // 注册新组件
  },
  computed: {
    personalPageCategories() {
      return personalPageCategories;
    }
  },
  methods: {
    navigateToPage(basePath) {
      this.$router.push(basePath);
    },
  }
};
</script>

<style scoped>
.menu-container {
  max-height: calc(100vh - 7vh - 7rem);
  overflow-y: auto;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: #ffffff;
}

.menu-item:hover {
  background-color: #ececec;
}

.menu-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border-radius: 8px;
}

.menu-text {
  flex-grow: 1;
}

.menu-title {
  font-size: 0.85rem;
  font-weight: 600;
  color: #333;
}

.menu-arrow {
  font-size: 1.2rem;
  color: #9a9a9a;
}

.divider {
  height: 1px;
  background-color: #eeeeee;
}

.mini-menu-grid {
  margin-top: 3%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  margin-left: 3%;
  margin-right: 3%;
}

.mini-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mini-item-icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 0.3rem;
}

.mini-item-title {
  font-size: 0.8rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>